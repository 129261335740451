body {
    background-color: #4CD4CB;
}

.title {
    font-size: 64px;
    margin-bottom: -30px;
}

.subtitle {
    font-size: 12px;
    font-family: nexa-rust-slab-black, sans-serif;
    font-style: normal;

}

    .stopWatchArea {
        font-family: "futura-pt", sans-serif;
        font-weight: 500;
        font-style: normal;
        
        //font-size: calc(1.25vw + 1.5rem);
        //font-size: 20vw;
        /* 最小1.2rem | SP:1.4rem | TB:1.5rem | PC:1.6rem | 最大1.8rem */
        font-size: clamp(1.2rem, calc(20vw + 1.5rem), 22rem);
    }


.btnArea {

    font-family: "futura-pt", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.App {
    text-align: center;
    font-family: lato, sans-serif;
    font-style: italic;
    font-weight: 100;
}

input {
    font-size: 40px;
}

button {
    font-family: nexa-rust-sans-black-2, sans-serif;
    font-style: normal;
    font-weight: 100;

    display: inline-block;
    outline: none;
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    border-radius: 8px;
    padding: 28px 70px;
    border: 1px solid #222222;
    transition: box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s;
    background: #fff;
    color: #222222;
    margin: 12px;

    :hover {
        border-color: #000000;
        background: #f7f7f7;
    }
}

#targettime {
    font-family: nexa-rust-slab-black, sans-serif;
    font-style: normal;
    font-size: 18px;
}

#goalAnimation {
    z-index: 100;

    /*　画面全体を覆う設定　*/
    position: fixed;
    position: -webkit-fixed;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    /*　画面の中央に要素を表示させる設定　*/
    /* display: flex; StopWatch.tsxにて描画設定 */
    align-items: center;
    justify-content: center;

    /* 描画範囲を確認 */
    /* background-color:rgba(0,0,0,0.5); */

}